import styles from './router-map.module.scss';
import classNames from 'classnames';
import React from 'react';
import { useContext, useEffect, useState, useMemo } from 'react';
import { GoogleMap,  Polyline, Marker, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { Loader } from '../loader/loader';
import {  AxiosResponse } from 'axios';
import { getUniques, queryV1, merge } from '../../services/python-services';
import { bounds, query_glp_last_refresh, route_map, route_map_0, route_map_1, styles_route_map } from '../../utils/constants';
import { GLPMapFilter } from '../glp-ports/glp-ports';
import { arraysEqual, convertKeyToValue, convertRouteToCoordinates, getArrayLikeMultipleQueries, shouldBeNull } from '../../utils/functions';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';
import { ArrowPathIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { Filter } from '../../utils/interfaces';
import Notiflix from 'notiflix';
import { Button } from '../button/button';

export interface RouteMapProps {
    className?: string;
    name?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const RouteMap = ({ className }: RouteMapProps) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB1QRJq3dL5TrbDlCaHjTFbbFrs2GO6MRg',
        libraries: ["geometry"], // Include the geometry library
    });
    const [dataLoaded, setDataLoaded] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [lastRefresh, setLastRefresh] = useState('');
    const [showTooltip, setShowTooltip] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [portData, setPortData] = useState<any[]>([]);
    const [pathsData, setPathsData] = useState<any[]>([]);
    const [uniquesDepartureCntr, setUniquesDepartureCntr] = useState<any[]>([]);
    const [allUniquesDepartureCntr, setAllUniquesDepartureCntr] = useState<any[]>([]);
    const [uniquesDepartures, setUniquesDepartures] = useState<any[]>([]);
    const [allUniquesDepartures, setAllUniquesDepartures] = useState<any[]>([]);
    const [uniquesFinalCntr, setUniquesFinalCntr] = useState<any[]>([]);
    const [allUniquesFinalCntr, setAllUniquesFinalCntr] = useState<any[]>([]);
    const [uniquesFinal, setUniquesFinal] = useState<any[]>([]);
    const [allUniquesFinal, setAllUniquesFinal] = useState<any[]>([]);
    const [uniquesCarrier, setUniquesCarrier] = useState<any[]>([]);
    const [allUniquesCarrier, setAllUniquesCarrier] = useState<any[]>([]);
    const [loadingUniques, setLoadingUniques] = useState(true);


    const initialCoordinates = { lat: 20, lng: 10 };
    const [center, setCenter] = useState<any>(initialCoordinates);
    const initialZoom = 1.0;
    const [zoom, setZoom] = useState<any>(initialZoom);
    //filters
    const [isReset, setIsReset] = useState(false);
    const [applyFiltersAction, setApplyFilterAction] = useState(false);
    const [loadingFilteredData, setLoadingFilteredData] = useState(false);
    const initialProperties = {
        departurectry: null,
        departureport: null,
        finalctry: null,
        finalport: null,
        carrier: null,
    };
    const [properties, setProperties] = useState<Filter>(initialProperties);
    const initialFilters = {
        status: null,
        country_code: null,
    };
    const [filter, setFilter] = useState<GLPMapFilter>(initialFilters);

    const getPropertiesWithDecode = (properties: any) => {
        return Object.fromEntries(
            Object.entries(properties).map(([key, value]) => [
                key,
                value ? decodeURIComponent(value.toString()) : null,
            ])
        );
    };

    const fetchQueryData = async (routeMap: any, properties: any, datasource: string) => {
        try {
            return await queryV1(routeMap.table, routeMap.groupsBy, routeMap.query, getPropertiesWithDecode(properties), datasource);
        } catch (error) {
            console.error(`Error fetching data for ${routeMap.table}:`, error);
            throw error; // Rethrow to propagate the error
        }
    }

    // const adjustPointToSea = (point:any) => {
    //     // Check if the point is near a known port or waterway
    //     const SEA_LATITUDE_MIN = -85; // Southern ocean
    //     const SEA_LATITUDE_MAX = 85;  // Northern Arctic
    //     const SEA_LONGITUDE_MIN = -180; // International Dateline West
    //     const SEA_LONGITUDE_MAX = 180;  // International Dateline East

    //     // If the point is outside ocean coordinates, adjust it
    //     if (
    //       point.lat < SEA_LATITUDE_MIN ||
    //       point.lat > SEA_LATITUDE_MAX ||
    //       point.lng < SEA_LONGITUDE_MIN ||
    //       point.lng > SEA_LONGITUDE_MAX
    //     ) {
    //       return {
    //         lat: Math.max(SEA_LATITUDE_MIN, Math.min(SEA_LATITUDE_MAX, point.lat)),
    //         lng: Math.max(SEA_LONGITUDE_MIN, Math.min(SEA_LONGITUDE_MAX, point.lng)),
    //       };
    //     }

    //     // Example nudging if the point is inland (pseudo-check for simplicity)
    //     point.lat += 0.05; // Push north
    //     point.lng -= 0.05; // Push west
    //     return point;
    //   };
    // const getSeaPaths = async (paths: any) => {
    //     let allSeaPahts: any = [];
    //     for (const path of paths) {
    //         const seaPath = [];
    //         for (let i = 0; i < path.length - 1; i++) {
    //           const start = path[i];
    //           const end = path[i + 1];

    //           // Generate intermediate points
    //           const numPoints = 40; // Increase for smoother lines
    //           for (let t = 0; t <= 1; t += 1 / numPoints) {
    //             const intermediate = google.maps.geometry.spherical.interpolate(
    //               new google.maps.LatLng(start.lat, start.lng),
    //               new google.maps.LatLng(end.lat, end.lng),
    //               t
    //             );

    //             const adjustedPoint = adjustPointToSea({
    //               lat: intermediate.lat(),
    //               lng: intermediate.lng(),
    //             });
    //             seaPath.push(adjustedPoint);
    //           }
    //         }
    //         allSeaPahts.push(seaPath);
    //         // // Draw the polyline
    //         // new google.maps.Polyline({
    //         //   path: seaPath,
    //         //   geodesic: true,
    //         //   strokeColor: "#0000FF",
    //         //   strokeOpacity: 0.7,
    //         //   strokeWeight: 2,
    //         //   map,
    //         // });
    //       }

    //     console.log("paths", paths)
    //     console.log("allSeaPahts", allSeaPahts);
    //     return allSeaPahts;
    // };
    const getMapData = async () => {

        setRefresh(true);
        let ports: any = [];
        if (lastRefresh === '') {
            let last_refresh: Promise<any> | AxiosResponse = queryV1(
                query_glp_last_refresh.table,
                query_glp_last_refresh.groupsBy,
                query_glp_last_refresh.query,
                {},
                'athena'
            );

            last_refresh = (await last_refresh) as AxiosResponse;
            let date: any = last_refresh.data[0].dt.replace('T', '-').replaceAll(':', '-').split('-');
            setLastRefresh(`${date[0]}-${date[1]}-${date[2]} ${date[3]}:${date[4]}`);
        }
        try {
            // Fetch both responses concurrently

            const [leadcalcResponse, shipmentResponse] = await Promise.all([
                fetchQueryData(route_map_0, properties, 'athena'),
                fetchQueryData(route_map_1, properties, 'athena'),
            ]);

            // Merge the data
            const mergedData = await merge(
                'departureport$port_1sttransit_port$port_2ndtransit_port$port_3rdtransit_port$port_4thtransit_port$discharging__port$finalport',
                [
                    leadcalcResponse.data,
                    shipmentResponse.data,
                ]);
            // console.log('Merged data:', mergedData);
            ports = mergedData;
            // return mergedData;
        } catch (error) {
            console.error('Error processing data:', error);
            setDataLoaded(true);
            Notiflix.Notify.info("Info No data founded", () => { setTimeout(() => { }, 1000) })
            throw error; // Propagate the error
        }
        const coordinates = convertRouteToCoordinates(ports);
        setPortData(coordinates?.coordinates);
        setPathsData(coordinates?.paths);
        setZoom(initialZoom);
        if (ports && ports?.length > 0) {
            if (properties.departurectry !== null || properties.finalctry !== null) {
                const coun = ports[0]
                if (coun !== null) {
                    setCenter({
                        lat: parseFloat(coun.dep_latitude),
                        lng: parseFloat(coun.dep_longitude) + 50,
                    });
                } else {
                    setCenter(initialCoordinates);
                }
            } else {
                setCenter(initialCoordinates);
            }
        } else {
            setCenter(initialCoordinates);
        }
        setDataLoaded(true);
        setLoadingFilteredData(false);
        setRefresh(false);
        if (applyFilter) {
            setApplyFilter(false);
        }
    };

    const getUniquesDeparturesCntr = async () => {
        const response = await getUniques(route_map.table, 'departurectry', {}, 'athena', false);
        // const response = await getUniques(route_map.table, 'departurectry', getPropertiesWithDecode({ ...properties, departurectry: null }), 'athena', false);
        const uniques = convertKeyToValue(response, 'departurectry', properties)
        // if(!arraysEqual(uniques,uniquesDepartureCntr)){
        setUniquesDepartureCntr(uniques)
        setAllUniquesDepartureCntr(uniques)
        // }
    }
    const getUniquesDepartures = async () => {
        const response = await getUniques(route_map.table, 'departureport', getPropertiesWithDecode({ ...properties, departureport: null }), 'athena', false);
        // const response = await getUniques(route_map.table, 'departureport', getPropertiesWithDecode({ ...properties }), 'athena', false);
        const uniques = convertKeyToValue(response, 'departureport', properties)
        if (!arraysEqual(uniques, uniquesDepartures)) {
            setUniquesDepartures(uniques)
            setAllUniquesDepartures(uniques)
        }
    }
    const getUniquesFinalCntr = async () => {
        const response = await getUniques(route_map.table, 'finalctry', getPropertiesWithDecode({ ...properties,finalctry:null }), 'athena', false);
        const uniques = convertKeyToValue(response, 'finalctry', properties)
        if (!arraysEqual(uniques, uniquesFinalCntr)) {
            setUniquesFinalCntr(uniques)
            setAllUniquesFinalCntr(uniques)
        }
    }
    const getUniquesFinal = async () => {
        // const response = await getUniques(route_map.table, 'finalport', getPropertiesWithDecode({ ...properties, finalport: null, departurectry: null }), 'athena', false);
        const response = await getUniques(route_map.table, 'finalport', getPropertiesWithDecode({ ...properties,finalport:null }), 'athena', false);
        const uniques = convertKeyToValue(response, 'finalport', properties)
        if (!arraysEqual(uniques, uniquesFinal)) {
            setUniquesFinal(uniques)
            setAllUniquesFinal(uniques)
        }
    }
    const getUniquesCarrier = async () => {
        const response = await getUniques(route_map.table, 'carrier', getPropertiesWithDecode({ ...properties ,carrier:null}), 'athena', false);
        const uniques = convertKeyToValue(response, 'carrier', properties)
        if (!arraysEqual(uniques, uniquesCarrier)) {
            setUniquesCarrier(uniques)
            setAllUniquesCarrier(uniques)
        }

    }

    const applyChangeOptions = (arrayP: any, name: string) => {
        if ((arrayP.hasOwnProperty(name) && arrayP[name] !== null) || (arrayP.departurectry !== null || arrayP.finalctry !== null)) {
            return true;
        }
        return false;
    }
    const getAllUniques = async () => {
        if (applyChangeOptions(properties, 'departureport') || isReset || !dataLoaded) {
            await getUniquesDepartures();
        }
        if (applyChangeOptions(properties, 'finalctry') || isReset || !dataLoaded) {
            await getUniquesFinalCntr();
        }
        if (applyChangeOptions(properties, 'finalport') || isReset || !dataLoaded) {
            await getUniquesFinal();
        }
        if (applyChangeOptions(properties, 'carrier') || isReset || !dataLoaded) {
            await getUniquesCarrier();
        }
        setLoadingUniques(false);
    }
    useEffect(() => {
        if (!dataLoaded || applyFilter) {
            getMapData();
            getUniquesDeparturesCntr();

        }
    }, [dataLoaded, applyFilter]);
    useEffect(() => {
        getAllUniques();
    }, [properties]);

    useEffect(() => {
        // console.log("applyFiltersAction", properties)
        if (applyFiltersAction) {
            setLoadingFilteredData(true);
            // applyFilters(properties, true);
            getMapData();
            if(isReset)
            getUniquesDeparturesCntr();
            setApplyFilterAction(false);
        }
    }, [applyFiltersAction]);

    const handleReset = () => {
        setIsReset(true);
        setProperties(initialProperties);
        setUniquesDepartureCntr(allUniquesDepartureCntr);
        setUniquesDepartures(allUniquesDepartures);
        setUniquesFinalCntr(allUniquesFinalCntr);
        setUniquesFinal(allUniquesFinal);
        setUniquesCarrier(allUniquesCarrier);
        
        setApplyFilterAction(true);
    };
    const mapContainerStyle = {
        height: '90%',
        width: '100%'
    };

    const onClickPort = (port: any) => {
        setShowTooltip(port.value);
    }

    const options = {
        strokeColor: '#66e0ff',//'#067eff',
        strokeOpacity: 1.0,
        strokeWeight: 6,
        geodesic: false,// This makes the line straight
    };

    const getFiltersComponent = () => {
        // console.log("getFiltersComponent", uniquesDepartureCntr)
        return <div className={styles['filter']}>
            <div className={styles['filterOption']}>
                <label className={styles.filterLabel} htmlFor="departurectry">Departure Country:</label>
                <MultiSelectDropdown
                    icon={<GlobeAltIcon className="w-4 h-4 text-gray-500" />}
                    options={uniquesDepartureCntr}
                    chooseLegend={'Departure Country'}
                    onChange={(v: any) => {
                        const setNull = shouldBeNull(v);
                        const updateQuery: any = {
                            ...properties,
                            departurectry: setNull ? '' : (v.length === 0 || v.length === uniquesDepartureCntr.length) ? null : getArrayLikeMultipleQueries(v),
                        };
                        setLoadingUniques(true);
                        setProperties({ ...updateQuery });
                        // setApplyFilterAction(true);


                    }}
                    reset={isReset}
                    // filtersOpened={filtersOpened}
                    // setFiltersOpened={setFiltersOpened}
                    name="departurectry"
                />
            </div>
            <div className={styles['filterOption']}>
                <label className={styles.filterLabel} htmlFor="departureport">Departure Port:</label>
                {loadingUniques ?
                    <h1 className={styles.loaderUniques}>
                        <Loader></Loader>
                    </h1> :

                    <MultiSelectDropdown
                        icon={<GlobeAltIcon className="w-4 h-4 text-gray-500" />}
                        options={uniquesDepartures}
                        chooseLegend={'Departure Port'}
                        onChange={(v: any) => {
                            const setNull = shouldBeNull(v);
                            if (!setNull && v.length > 0) {
                                const updateQuery: any = {
                                    ...properties,
                                    departureport: setNull ? null : (v.length === 0 || v.length === uniquesDepartures.length) ? null : getArrayLikeMultipleQueries(v),
                                };
                                setLoadingUniques(true);
                                setProperties(updateQuery);
                            }
                            // setApplyFilterAction(true);

                        }}
                        reset={isReset}
                        // filtersOpened={filtersOpened}
                        // setFiltersOpened={setFiltersOpened}
                        name="departureport"
                    />
                }
            </div>
            <div className={styles['filterOption']}>
                <label className={styles.filterLabel} htmlFor="finalctry">Final Country:</label>
                {loadingUniques ?
                    <h1 className={styles.loaderUniques}>
                        <Loader></Loader>
                    </h1> :
                    <MultiSelectDropdown
                        icon={<GlobeAltIcon className="w-4 h-4 text-gray-500" />}
                        options={uniquesFinalCntr}
                        chooseLegend={'Final Country'}
                        onChange={(v: any) => {
                            const setNull = shouldBeNull(v);
                            if (!setNull && v.length > 0) {
                                const updateQuery: any = {
                                    ...properties,
                                    finalctry: setNull ? null : (v.length === 0 || v.length === uniquesFinalCntr.length) ? null : getArrayLikeMultipleQueries(v),
                                };
                                setLoadingUniques(true);
                                setProperties(updateQuery);
                            }
                            // setApplyFilterAction(true);
                        }}
                        reset={isReset}
                        // filtersOpened={filtersOpened}
                        // setFiltersOpened={setFiltersOpened}
                        name="finalctry"
                        stlylePorts={true}
                    />
                }
            </div>
            <div className={styles['filterOption']}>
                <label className={styles.filterLabel} htmlFor="finalport">Final Port:</label>
                {loadingUniques ?
                    <h1 className={styles.loaderUniques}>
                        <Loader></Loader>
                    </h1> :
                    <MultiSelectDropdown
                        icon={<GlobeAltIcon className="w-4 h-4 text-gray-500" />}
                        options={uniquesFinal}
                        chooseLegend={'Final Port'}
                        onChange={(v: any) => {
                            const setNull = shouldBeNull(v);
                            if (!setNull && v.length > 0) {
                                const updateQuery: any = {
                                    ...properties,
                                    finalport: setNull ? null : (v.length === 0 || v.length === uniquesFinal.length) ? null : getArrayLikeMultipleQueries(v),
                                };
                                setLoadingUniques(true);
                                setProperties(updateQuery);
                            }
                            // setApplyFilterAction(true);

                        }}
                        reset={isReset}
                        // filtersOpened={filtersOpened}
                        // setFiltersOpened={setFiltersOpened}
                        name="finalport"
                    />
                }
            </div>
            <div className={styles['filterOption']}>
                <label className={styles.filterLabel} htmlFor="carrier">Shipping:</label>
                {loadingUniques ?
                    <h1 className={styles.loaderUniques}>
                        <Loader></Loader>
                    </h1> :
                    <MultiSelectDropdown
                        icon={<GlobeAltIcon className="w-4 h-4 text-gray-500" />}
                        options={uniquesCarrier}
                        chooseLegend={'Shipping'}
                        onChange={(v: any) => {
                            const setNull = shouldBeNull(v);
                            if (!setNull && v.length > 0) {
                                const updateQuery: any = {
                                    ...properties,
                                    carrier: setNull ? null : (v.length === 0 || v.length === uniquesCarrier.length) ? null : getArrayLikeMultipleQueries(v),
                                };
                                setLoadingUniques(true);
                                setProperties(updateQuery);
                                // setApplyFilterAction(true);
                            }
                        }}
                        reset={isReset}
                        // filtersOpened={filtersOpened}
                        // setFiltersOpened={setFiltersOpened}
                        name="discharging__port"
                    />
                }
            </div>
            <div className={styles['filterOption']}>
                <Button click={() => { setApplyFilterAction(true); }}
                    styleProp="inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring border-primary-buttons text-white bg-primary-buttons hover:bg-white hover:text-primary-buttons" >
                    Apply
                </Button>
            </div>
            <div className={styles['filterOption']}>
                <ArrowPathIcon onClick={handleReset} className={'h-4 w-4 '} style={{ cursor: 'pointer' }} />
            </div>
            {loadingFilteredData ? <h1 className={styles.loaderFilters}><Loader></Loader></h1> :
                <div style={{ width: '22%' }}></div>}
            <div style={{ marginRight: '25px' }} className={styles.lastRefresh}>
                <div>Last refresh date: {lastRefresh}</div>

            </div>
        </div >
    }

    return (
        <div className={classNames(styles.container, className) + " " + styles.mapContainer}>
            <div className={styles.filterContainer}>
                {dataLoaded && getFiltersComponent()}
            </div>
            {!isLoaded || !dataLoaded ? (
                <h1>
                    <Loader></Loader>
                </h1>
            ) : (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={zoom}
                    options={{
                        styles: styles_route_map,
                        mapTypeControl: false,
                        restriction: {
                            latLngBounds: bounds,
                            strictBounds: true, // Enforce bounds strictly
                        },
                        minZoom: 2.8, // Adjust this to your needs
                    }}
                >

                    {portData?.length > 0 && portData?.map((port: any, index: number) => {
                        return (
                            <Marker key={"markPort-" + index} position={{ lat: port.lat, lng: port.lng }}
                                icon={{
                                    url: '/images/circle-' + ((port.departure === true) ? 'main.png' : (port.final === true) ?'closed.png': 'empty.png'  ),
                                    scaledSize: new google.maps.Size(18, 18),
                                }}
                                onClick={() => onClickPort(port)}
                                onMouseOut={() => setShowTooltip('')}
                            >
                                {showTooltip === port.value && <InfoWindow options={{ disableAutoPan: true, maxWidth: 350, minWidth: 250 }} >
                                    <div className={styles.portsContainer}>
                                        <div className={styles.header}>
                                            <div>
                                                <h5 className={styles.portName}>Name: {port.value}</h5>
                                            </div>

                                            <div className={styles.information}>
                                                <div>

                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Latitude:
                                                        </h5>
                                                        <h5>{port.lat}</h5>
                                                    </div>
                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Longitude:
                                                        </h5>
                                                        <h5>{port.lng}</h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </InfoWindow>
                                }
                            </Marker>
                        )
                    })}

                    {pathsData?.length > 0 && pathsData?.map((path: any, index: number) => {
                        return (
                            <Polyline key={"path-" + index}
                                path={path}
                                options={options}
                            />
                        )
                    })}

                </GoogleMap>
            )}

        </div>
    );


};
