/* eslint-disable react-hooks/exhaustive-deps */
import { FunnelIcon, GlobeAltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FilterSection } from '../filter-section/filter-section';
import { OptionToggle } from '../option-toggle/option-toggle';
import { Button } from '../button/button';
import { useEffect, useState } from 'react';
import { Slicer } from '../slicer/slicer';
import { MultiComparisonFilters } from '../multi-comparison-filters/multi-comparison-filters';
import { Filter } from '../../utils/interfaces';
import { getUniques, getOrmUniquesValues } from '../../services/python-services';
import { ButtonGroup } from '../button-group/button-group';
import { areObjectsEqualByString, arrayIncludes } from '../../utils/functions';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';

export interface FilterCardProps {
    className?: string;
    close: (v: boolean) => void;
    filters: string[];
    uniques?: any;
    onReset: Function;
    file: string;
    applyFilters: Function;
    columns: object[];
    setUniques: Function;
    setCustomFilters: Function;
    customFilters: [];
    style?: object;
    by?: 'athena' | 'parquet';
    setExecuteApply?: Function;
    setApplySearch?: Function;
    isColapsed?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const FilterCard = ({
    close,
    applyFilters,
    columns,
    setUniques,
    filters,
    uniques,
    onReset,
    file,
    customFilters,
    setCustomFilters,
    style,
    by = 'parquet',
    setExecuteApply = () => {},
    setApplySearch = () => {},
    isColapsed = false,
}: FilterCardProps) => {
    const [components, setComponents] = useState<any>(customFilters);
    // console.log("uniques: ", uniques, uniques?.finalctry)
    const [properties, setProperties] = useState<Filter>({
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        sitefromname: null,
        gbm_name: null,
        divisionname: null,
        soldtoname: null,
        'country-glp': null,
        finalctry: uniques?.finalctry !== undefined ? uniques?.finalctry : null,
        shiptoname: null,
        kiosco: null,
        'delayeddays:not': null,
        delayeddays: null,
        photo: null,
        site_id: null,
        is_rmf: file === 'pos' ? '1' : null,
        universe: file === 'pos' ? '1' : null,
        status: file === 'pos' ? '1' : null,
        'country__name:not': file === 'pos' ? 'PARAGUAY' : null,
        arrived: null,
        country__name: null,
        company__value: null,
        user_status: null,
    });
    const [customProperties, setCustomProperties] = useState<any>({});
    const [finalQuery, setFinalQuery] = useState<any>({});
    const onApply = () => {
        setExecuteApply(true);
        setApplySearch(true);
        applyFilters(finalQuery, '', true);
    };
    // Slicers
    const [countries, setCountries] = useState([]);
    const [userCountries, setUserCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [regions, setRegions] = useState([]);
    const [zones, setZones] = useState([]);
    const [stores, setStores] = useState([]);
    const [productGroup, setProductGroup] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [productDivisions, setProductDivisions] = useState([]);
    const [segments, setSegments] = useState([]);
    const [sitefromname, setSitefromname] = useState([]);
    const [productGroupGLP, setProductGroupGLP] = useState([]);
    const [divitionGLP, setDivitionGLP] = useState([]);
    const [customerGLP, setCustomerGLP] = useState([]);
    const [countryGLP, setCountryGLP] = useState([]);
    const [year, setYear] = useState([]);
    const [week, setWeek] = useState([]);
    const [applyFiltersAction, setApplyFilterAction] = useState(false);
    //group button delayed days
    const [clickedGroupId, setClickedGroupId] = useState(properties['delayed'] || -1);

    const [clickedGroupIdArrived, setClickedGroupIdArrived] = useState(properties['arrived'] || -1);
    const [loadingData, setLoadingData] = useState(false);
    //state to management open and closed options
    const initialState: any[] = [];
    const [isReset, setIsReset] = useState(false);
    const [filtersOpened, setFiltersOpened] = useState(isColapsed ? initialState : filters);
    const handleReset = () => {
        setFiltersOpened(isColapsed ? initialState : filters);
        setProperties({
            point_of_sale_country: null,
            point_of_sale_region: null,
            point_of_sale_zone: null,
            point_of_sale_account: null,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: null,
            'delayeddays:not': null,
            delayeddays: null,
            kiosco: null,
            photo: null,
            site_id: null,
            delayed: null,
            country__name: null,
            company__value: null,
            user_status: null,
            is_rmf: file === 'pos' ? '1' : null,
            universe: file === 'pos' ? '1' : null,
            status: file === 'pos' ? '1' : null,
        });
        onReset();
        setCountries([]);
        setRegions([]);
        setZones([]);
        setStores([]);
        setProductGroup([]);
        setDivisions([]);
        setProductDivisions([]);
        setSegments([]);
        setSitefromname([]);
        setProductGroupGLP([]);
        setCustomerGLP([]);
        setCountryGLP([]);
        setComponents([]);
        setUserCountries([]);
        setCompanies([]);
        setUserStatus([]);
        applyFilters([]);
        setClickedGroupId(-1);
        setExecuteApply(true);
        setApplySearch(true);
        setDivitionGLP([]);
        setClickedGroupIdArrived(-1);
        // getCountryGLP();
        setIsReset(true);
        setTimeout(() => {
            setIsReset(false);
        }, 0);
    };

    const getArrayLikeQueries = (array: any) => {
        const join_map = array.map((a: any) => a.value).join(',');
        return join_map === '' ? null : join_map;
    };

    const OptionsAdapter = (label: string, value: string, optionsData: any) => {
        const results = optionsData?.map((o: any) => {
            return {
                label:
                    o[label] !== null
                        ? typeof o[label] === 'number'
                            ? o[label]
                            : o[label].replaceAll('_', ' ')
                        : '',
                value: o[value],
                check: true,
                active: true,
            };
        });
        return results;
    };

    const OptionsAdapterList = (optionsData: any) => {
        const results = optionsData?.map((o: any) => {
            return {
                label: o[1],
                value: o[0],
                check: true,
                active: true,
            };
        });
        return results;
    };

    /*     const getFiltersData = async () => {
        setLoadingData(true);
        filters?.forEach(async (f: string) => {
            console.log('f', f);
            if (filtersOpened.length > 0) {
                if (
                    (f === 'finalctry' || f === 'country__name') &&
                    (arrayIncludes(filtersOpened, 'user_country') ||
                        arrayIncludes(filtersOpened, 'finalctry'))
                ) {
                    console.log('getting in');
                    await getCountryGLP();
                }
                // console.log("f", f,filtersOpened)
            }
        });
        setLoadingData(false);
    }; */

    /*     useEffect(() => {
        //remove sort_by property in the uniques array
        const newUniques: any = uniques!;
        delete newUniques.sort_by;
        setUniques(newUniques);
        // console.log(`filtersOpened in UseEffect: ${filtersOpened} ,,, ${filtersOpened.length}  loadingData ${loadingData}, applyFilterAction ${applyFiltersAction}`)
        if (!loadingData && filtersOpened.length > 0) {
            getFiltersData();
            if (applyFiltersAction) {
                applyFilters({ ...properties }, true);
                setApplyFilterAction(false);
            }
        }
    }, [uniques, filtersOpened]); */

    const joinOptions = (options: any, options2: any) => {
        if (options?.length === 0) {
            return options2;
        } else {
            const updatedOptions = options?.map((option: any) => {
                if (!options2.some((o: any) => o.label === option.label)) {
                    return { ...option, active: false };
                } else {
                    return { ...option, active: true };
                }
            });

            return updatedOptions.sort((a: any, b: any) => {
                if (a.active && !b.active) {
                    return -1; // a viene antes que b
                } else if (!a.active && b.active) {
                    return 1; // a viene después de b
                } else {
                    return 0; // No se cambia el orden relativo entre a y b
                }
            });
        }
    };

    useEffect(() => {
        let filters = {};
        components
            .filter((c: any) => c[1] !== '' && c[3] !== '')
            .forEach((c: any) => {
                const propertie: string = `${c[1]}${c[2]}`;
                if (propertie in filters) {
                    //@ts-ignore
                    filters = { ...filters, [propertie]: `${filters[propertie]},${c[3]}` };
                } else {
                    filters = { ...filters, [`${c[1]}${c[2]}`]: c[3] };
                }
            });
        setCustomProperties(filters);
        setCustomFilters(components);
    }, [components]);

    useEffect(() => {
        setUniques({ ...customProperties, ...properties });
        setFinalQuery({ ...customProperties, ...properties });
    }, [properties]);

    useEffect(() => {
        setFinalQuery({ ...properties, ...customProperties });
        setUniques({ ...properties, ...customProperties });
    }, [customProperties]);

    useEffect(() => {
        //remove sort_by property in the uniques array
        const newUniques: any = uniques!;
        delete newUniques.sort_by;
        setUniques(newUniques);
        filters.forEach((f: string) => {
            if (filtersOpened.length > 0)
                if (f === 'country' && arrayIncludes(filtersOpened, 'country')) {
                    getCountryOptions();
                }
            if (f === 'user_country' && arrayIncludes(filtersOpened, 'user_country')) {
                getUserCountryOptions();
            }
            if (f === 'company' && arrayIncludes(filtersOpened, 'company')) {
                getCompanyOptions();
            }
            if (f === 'user_status' && arrayIncludes(filtersOpened, 'user_status')) {
                getUserStatusOptions();
            }
            if (f === 'region' && arrayIncludes(filtersOpened, 'region')) {
                getRegionOptions();
            }
            if (f === 'countryZone' && arrayIncludes(filtersOpened, 'countryZone')) {
                getZoneOptions();
            }
            if (f === 'store' && arrayIncludes(filtersOpened, 'store')) {
                getStoresOptions();
            }
            if (f === 'division' && arrayIncludes(filtersOpened, 'division')) {
                getDivisionOptions();
            }
            if (f === 'product-division' && arrayIncludes(filtersOpened, 'product-division')) {
                getProductDivisionOptions();
            }
            if (f === 'product-group' && arrayIncludes(filtersOpened, 'product-group')) {
                getProductGroupOptions();
            }
            if (f === 'segments' && arrayIncludes(filtersOpened, 'segments')) {
                getSegmentsOptions();
            }
            if (f === 'factory' && arrayIncludes(filtersOpened, 'factory')) {
                getFactory();
            }
            if (f === 'product-group-glp' && arrayIncludes(filtersOpened, 'product-group-glp')) {
                getProductGroupGLP();
            }
            if (f === 'gbm_name' && arrayIncludes(filtersOpened, 'gbm_name')) {
                getDivitionGLP();
            }
            if (f === 'customer-glp' && arrayIncludes(filtersOpened, 'customer-glp')) {
                getCustomerGLP();
            }
            if (f === 'country-glp' && arrayIncludes(filtersOpened, 'country-glp')) {
                getCountryGLP();
            }

            if (f === 'year' && arrayIncludes(filtersOpened, 'year')) {
                getYear();
            }
            if (f === 'week' && arrayIncludes(filtersOpened, 'week')) {
                getWeek();
            }
        });
        setLoadingData(false);
    }, [ filtersOpened, uniques.gbm_name, uniques.divisionname,uniques.shiptoname, uniques['country-glp'],
        uniques.country__name, uniques.company__value
    ]);

    // SERVICES
    const getCountryOptions = async () => {
        let countries_: any = await getUniques(
            file ?? '',
            'country__name$point_of_sale_country',
            uniques,
            by
        );
        countries_ = OptionsAdapter('country__name', 'point_of_sale_country', countries_);
        const updatedCountries: any = joinOptions(countries, countries_);
        setCountries(updatedCountries);
    };

    const getUserCountryOptions = async () => {
        let countries_: any = await getOrmUniquesValues('AllUserView', 'country__name', properties);
        countries_ = OptionsAdapter('country__name', 'country__name', countries_);
        const updatedCountries: any = joinOptions(userCountries, countries_);
        setUserCountries(updatedCountries);
    };

    const getCompanyOptions = async () => {
        let companies_: any = await getOrmUniquesValues(
            'AllUserView',
            'company__value',
            properties
        );
        companies_ = OptionsAdapter('company__value', 'company__value', companies_);
        const updatedCompanies: any = joinOptions(companies, companies_);
        setCompanies(updatedCompanies);
    };

    const getUserStatusOptions = async () => {
        let status_: any = await getOrmUniquesValues('AllUserView', 'user_status', properties);
        status_ = OptionsAdapter('user_status', 'user_status', status_);
        const updatedStatus: any = joinOptions(userStatus, status_);
        setUserStatus(updatedStatus);
    };
    const getRegionOptions = async () => {
        let region_: any = await getUniques(
            file ?? '',
            'region__name$point_of_sale_region',
            uniques,
            by
        );
        region_ = OptionsAdapter('region__name', 'point_of_sale_region', region_);

        const updatedRegions: any = joinOptions(regions, region_);

        setRegions(updatedRegions);
    };

    const getZoneOptions = async () => {
        let zone_: any = await getUniques(file ?? '', 'zone__name$point_of_sale_zone', uniques, by);
        zone_ = OptionsAdapter('zone__name', 'point_of_sale_zone', zone_);

        const updatedZones: any = joinOptions(zones, zone_);
        setZones(updatedZones);
    };
    const getStoresOptions = async () => {
        let store_: any = await getUniques(
            file ?? '',
            'custom_account__name$point_of_sale_account',
            uniques,
            by
        );
        store_ = OptionsAdapter('custom_account__name', 'point_of_sale_account', store_);

        const updatedStores: any = joinOptions(stores, store_);

        setStores(updatedStores);
    };

    const getDivisionOptions = async () => {
        let division_: any = await getUniques(file ?? '', 'division__value$division', uniques, by);
        division_ = OptionsAdapter('division__value', 'division', division_);

        const updatedDivisions: any = joinOptions(divisions, division_);
        setDivisions(updatedDivisions);
    };

    const getProductDivisionOptions = async () => {
        let product_division_: any = await getUniques(
            file ?? '',
            'division__value$product_division',
            uniques,
            by
        );
        product_division_ = OptionsAdapter(
            'division__value',
            'product_division',
            product_division_
        );
        const updatedDivisions: any = joinOptions(productDivisions, product_division_);
        setProductDivisions(updatedDivisions);
    };

    const getProductGroupOptions = async () => {
        let productGroup_: any = await getUniques(file ?? '', 'group__value$group', uniques, by);
        productGroup_ = OptionsAdapter('group__value', 'group', productGroup_);

        const updatedProductGroup: any = joinOptions(productGroup, productGroup_);
        setProductGroup(updatedProductGroup);
    };

    const getSegmentsOptions = async () => {
        let segments_: any = await getUniques(file ?? '', 'segment__value$segment', uniques, by);
        segments_ = OptionsAdapter('segment__value', 'segment', segments_);
        const updatedSegments: any = joinOptions(segments, segments_);
        setSegments(updatedSegments);
    };

    const getFactory = async () => {
        let _sitefromname: any = await getUniques(file ?? '', 'carrier', uniques, by);
        _sitefromname = OptionsAdapter('carrier', 'carrier', _sitefromname);
        const updatedFactory: any = joinOptions(sitefromname, _sitefromname);
        setSitefromname(updatedFactory);
    };
    const getProductGroupGLP = async () => {
        let initValues: any = await getUniques(file ?? '', 'divisionname', uniques, by);
        initValues = OptionsAdapter('divisionname', 'divisionname', initValues);
        const updatedValues: any = joinOptions(productGroupGLP, initValues);
        setProductGroupGLP(updatedValues);
    };

    const getDivitionGLP = async () => {
        let initValues: any = await getUniques(file ?? '', 'gbm_name', uniques, by);
        initValues = OptionsAdapter('gbm_name', 'gbm_name', initValues);
        const updatedValues: any = joinOptions(divitionGLP, initValues);
        setDivitionGLP(updatedValues);
    };
    const getCustomerGLP = async () => {
        let initValues: any = await getUniques(file ?? '', 'shiptoname', uniques, by);
        initValues = OptionsAdapter('shiptoname', 'shiptoname', initValues);
        const updatedValues: any = joinOptions(customerGLP, initValues);
        setCustomerGLP(updatedValues);
    };
    useEffect(() => {
        setCustomerGLP([]);
        setProductGroupGLP([]);
        //getCountryGLP();
    }, []);

    useEffect(() => {}, [uniques, properties]);

    const getCountryGLP = async () => {
        let initValues: any = await getUniques(file ?? '', 'finalctry', uniques, by);
        initValues = OptionsAdapter('finalctry', 'finalctry', initValues);
        const updatedValues: any = joinOptions(countryGLP, initValues);
        setCountryGLP(updatedValues);
    };

    const getYear = async () => {
        let initValues: any = await getUniques(file ?? '', 'year', uniques, by);
        initValues = OptionsAdapter('year', 'year', initValues);
        const updatedValues: any = joinOptions(year, initValues);
        setYear(updatedValues);
    };

    const getWeek = async () => {
        let initValues: any = await getUniques(file ?? '', 'week', uniques, by);
        initValues = OptionsAdapter('week', 'week', initValues);
        const updatedValues: any = joinOptions(week, initValues);
        setWeek(updatedValues);
    };

    const shouldBeNull = (v: any) => {
        return v.some((a: any) =>
            typeof a !== 'object' ? a.toUpperCase() === 'ALL' : a?.label?.toUpperCase() === 'ALL'
        );
    };

    const getArrayLikeMultipleQueries = (array: any) => {
        const join_map = array.map((a: any) => a).join(',');
        return join_map === '' ? null : join_map;
    };
    return (
        <div style={style} className="min-w-[20%] max-w-[400px] overflow-auto bg-white p-4">
            <div className="flex items-center justify-between">
                <div className="mb-4 flex items-center gap-2 text-xl">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        style={{ height: '20px', color: '#262a34' }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                        />
                    </svg>
                    <p className="">Filters</p>
                </div>
                <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={() => close(true)} />
            </div>
            <FilterSection title="DATA INFORMATION" className="flex flex-col gap-3 mb-8">
                {filters?.includes('country') && (
                    <Slicer
                        title={'Country'}
                        options={countries}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                point_of_sale_country: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('user_country') && (
                    <Slicer
                        title={'Country'}
                        options={userCountries}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                country__name: getArrayLikeQueries(v),
                                company__value: null,
                                user_status: null,
                            };
                            setCompanies([]);
                            setUserStatus([]);
                            setProperties(updateQuery);
                            if(!areObjectsEqualByString(properties, updateQuery))
                                setLoadingData(true);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'user_country')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'user_country'}
                        loadingParent={loadingData}
                    />
                )}
                {filters?.includes('company') && (
                    <Slicer
                        title={'Company'}
                        options={companies}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                company__value: getArrayLikeQueries(v),
                                user_status: null,
                            };
                            setUserStatus([]);
                            setProperties(updateQuery);
                            if(!areObjectsEqualByString(properties, updateQuery))
                                setLoadingData(true);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'company')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'company'}
                        loadingParent={loadingData}
                    />
                )}
                {filters?.includes('user_status') && (
                    <Slicer
                        title={'User Status'}
                        options={userStatus}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                user_status: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'user_status')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'user_status'}
                        loadingParent={loadingData}
                    />
                )}
                {filters?.includes('region') && (
                    <Slicer
                        title={'Region'}
                        options={regions}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                point_of_sale_region: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('countryZone') && (
                    <Slicer
                        title={'Country Zone'}
                        options={zones}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                point_of_sale_zone: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('store') && (
                    <Slicer
                        title={'Account'}
                        options={stores}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                point_of_sale_account: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('division') && (
                    <Slicer
                        title={'Division'}
                        options={divisions}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                division: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                        isOpen={false}
                    />
                )}
                {filters?.includes('product-division') && (
                    <Slicer
                        title={'Division'}
                        options={productDivisions}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                product_division: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('product-group') && (
                    <Slicer
                        title={'Product Group'}
                        options={productGroup}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                group: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                        isOpen={false}
                    />
                )}
                {filters?.includes('segments') && (
                    <Slicer
                        title={'Segments'}
                        options={segments}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                segment: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('sitefromname') && (
                    <Slicer
                        title={'Factory'}
                        options={sitefromname}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                factory: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('gbm_name') && (
                    <Slicer
                        title={'Division'}
                        options={divitionGLP}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                gbm_name: getArrayLikeQueries(v),
                                divisionname: null,
                                shiptoname: null,
                            };
                            
                            if(!areObjectsEqualByString(properties, updateQuery))
                                setLoadingData(true);
                            setProperties(updateQuery);
                            setProductGroupGLP([]);
                            setCustomerGLP([]);
                            
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'gbm_name')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'gbm_name'}
                        loadingParent={loadingData}
                    />
                )}
                {filters?.includes('product-group-glp') && (
                    <Slicer
                        title={'Product Group'}
                        options={productGroupGLP}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                divisionname: getArrayLikeQueries(v),
                                shiptoname: null,
                            };
                            if(!areObjectsEqualByString(properties, updateQuery))
                                setLoadingData(true);
                            setProperties(updateQuery);
                            setCustomerGLP([]);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'product-group-glp')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'product-group-glp'}
                        loadingParent={loadingData}
                    />
                )}

                {filters?.includes('customer-glp') && (
                    <Slicer
                        title={'Customer'}
                        options={customerGLP}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                shiptoname: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                            // setLoadingData(true);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'customer-glp')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'customer-glp'}
                        loadingParent={loadingData}
                    />
                )}

                {/*  {filters?.includes('country-glp') && (
                    <Slicer
                        title={'Country'}
                        options={countryGLP}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                finalctry: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                        isOpen={arrayIncludes(filtersOpened, 'country-glp')}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        type={'country-glp'}
                        compactMode={true}
                    />
                )} */}
                {filters?.includes('country-glp') && (
                    <MultiSelectDropdown
                        options={countryGLP}
                        chooseLegend={'Country'}
                        onChange={(v: any) => {
                            const setNull = shouldBeNull(v);
                            const updateQuery: any = {
                                ...properties,
                                finalctry: setNull ? null : getArrayLikeMultipleQueries(v),
                            };
                            setProperties(updateQuery);
                            setApplyFilterAction(true);
                        }}
                        filtersOpened={filtersOpened}
                        setFiltersOpened={setFiltersOpened}
                        name="country-glp"
                        reset={isReset}
                    />
                )}

                {filters?.includes('year') && (
                    <Slicer
                        title={'Year'}
                        options={year}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                year: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('week') && (
                    <Slicer
                        title={'Week'}
                        options={week}
                        selected={(v: any) => {
                            const updateQuery: any = {
                                ...properties,
                                week: getArrayLikeQueries(v),
                            };
                            setProperties(updateQuery);
                        }}
                    />
                )}
                {filters?.includes('photo') && (
                    <OptionToggle
                        label="Photos"
                        idToggle="Photos"
                        onChangeToggle={(v) => {
                            const updateQuery: any = {
                                ...properties,
                                photo: v ? '1' : null,
                            };
                            setProperties(updateQuery);
                            applyFilters(updateQuery);
                        }}
                        value={properties.photo === '1'}
                    />
                )}
                {filters?.includes('is_rmf') && (
                    <OptionToggle
                        label="Is RMF"
                        idToggle="RMF"
                        onChangeToggle={(v) => {
                            const updateQuery: any = {
                                ...properties,
                                is_rmf: v ? '1' : null,
                            };
                            setProperties(updateQuery);
                            applyFilters(updateQuery);
                        }}
                        value={properties.is_rmf === '1'}
                    />
                )}
                {filters?.includes('universe') && (
                    <OptionToggle
                        label="Universe"
                        idToggle="Universe"
                        onChangeToggle={(v) => {
                            const updateQuery: any = {
                                ...properties,
                                universe: v ? '1' : null,
                            };
                            setProperties(updateQuery);
                            applyFilters(updateQuery);
                        }}
                        value={properties.universe === '1'}
                    />
                )}
                {filters?.includes('status') && (
                    <OptionToggle
                        label="Status"
                        idToggle="Status"
                        onChangeToggle={(v) => {
                            const updateQuery: any = {
                                ...properties,
                                status: v ? '1' : null,
                            };
                            setProperties(updateQuery);
                            applyFilters(updateQuery);
                        }}
                        value={properties.status === '1'}
                    />
                )}
                {filters?.includes('anntena') && (
                    <OptionToggle
                        label="Is Antenna Shop"
                        idToggle="Antenna"
                        onChangeToggle={(v) => {
                            const updateQuery: any = {
                                ...properties,
                                antenna: v ? '1' : null,
                            };
                            setProperties(updateQuery);
                            applyFilters(updateQuery);
                        }}
                        value={properties.antenna === '1'}
                    />
                )}
            </FilterSection>
            {file !== 'products' && (
                <FilterSection title="NOT MATCHING DATA">
                    {filters.includes('country') && (
                        <OptionToggle
                            idToggle="Country"
                            label="Country"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    point_of_sale_country: v ? 1 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery);
                            }}
                            value={properties.point_of_sale_country === 1}
                        />
                    )}
                    {filters?.includes('region') && (
                        <OptionToggle
                            idToggle="Region"
                            label="Region"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    point_of_sale_region: v ? 1 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery);
                            }}
                            value={properties.point_of_sale_region === 1}
                        />
                    )}
                    {filters?.includes('countryZone') && (
                        <OptionToggle
                            idToggle="CountryZone"
                            label="Country zone"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    point_of_sale_zone: v ? 1 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery);
                            }}
                            value={properties.point_of_sale_zone === 1}
                        />
                    )}
                    {filters?.includes('store') && (
                        <OptionToggle
                            idToggle="Store"
                            label="Store"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    point_of_sale_account: v ? 1 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery);
                            }}
                            value={properties.point_of_sale_account === 1}
                        />
                    )}
                    {filters?.includes('site_id') && (
                        <OptionToggle
                            idToggle="SiteId"
                            label="Site ID"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    site_id: v ? 1 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery);
                            }}
                            value={properties.site_id === 1}
                        />
                    )}
                    {filters?.includes('delayeddays') && (
                        <OptionToggle
                            idToggle="delayeddays"
                            label="Delayed"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    'delayeddays:gt': v ? 0 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery, '', false);
                            }}
                            value={properties['delayeddays:gt'] === 0}
                        />
                    )}
                    {filters?.includes('on-time') && (
                        <OptionToggle
                            idToggle="on_time"
                            label="On Time"
                            onChangeToggle={(v) => {
                                const updateQuery: any = {
                                    ...properties,
                                    delayeddays: v ? 0 : null,
                                };
                                setProperties(updateQuery);
                                applyFilters(updateQuery, '', false);
                            }}
                            value={properties['delayeddays'] === 0}
                        />
                    )}
                    {filters?.includes('delayed') && (
                        <ButtonGroup
                            id="delayed"
                            label={'Delayed'}
                            buttons={['N/A', 'YES', 'NO']}
                            onChange={(v: number) => {
                                let updateQuery: any;
                                if (v === 1) {
                                    updateQuery = {
                                        ...properties,
                                        'delayeddays:gt': 0,
                                        'delayeddays:lt': null,
                                    };
                                } else if (v === 2) {
                                    updateQuery = {
                                        ...properties,
                                        'delayeddays:gt': null,
                                        'delayeddays:lt': 1,
                                    };
                                } else {
                                    updateQuery = {
                                        ...properties,
                                        delayeddays: null,
                                        'delayeddays:gt': null,
                                        'delayeddays:lt': null,
                                    };
                                }
                                setProperties(updateQuery);
                                applyFilters(updateQuery, '', false);
                            }}
                            clickedGroupId={clickedGroupId}
                            setClickedGroupId={setClickedGroupId}
                        />
                    )}
                    {filters?.includes('arrived') && (
                        <ButtonGroup
                            id="arrived"
                            label={'Arrived'}
                            buttons={['N/A', 'YES', 'NO']}
                            onChange={(v: number) => {
                                let updateQuery: any;
                                // console.log('Arrived:', v);
                                if (v === 1) {
                                    updateQuery = {
                                        ...properties,
                                        arrived: true,
                                    };
                                } else if (v === 2) {
                                    updateQuery = {
                                        ...properties,
                                        arrived: false,
                                    };
                                } else {
                                    updateQuery = {
                                        ...properties,
                                        arrived: null,
                                    };
                                }
                                setProperties(updateQuery);
                                applyFilters(updateQuery, '', false);
                            }}
                            clickedGroupId={clickedGroupIdArrived}
                            setClickedGroupId={setClickedGroupIdArrived}
                        />
                    )}
                </FilterSection>
            )}
            <FilterSection title="custom filters">
                <MultiComparisonFilters
                    columns={columns}
                    components={components}
                    setComponents={setComponents}
                />
            </FilterSection>

            <div className="mt-10 flex w-full justify-between">
                <Button
                    click={handleReset}
                    styleProp="inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring border-primary-buttons bg-white text-primary-buttons hover:bg-primary-buttons hover:text-white"
                >
                    Reset
                </Button>
                <Button
                    click={onApply}
                    styleProp="inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring border-primary-buttons text-white bg-primary-buttons hover:bg-white hover:text-primary-buttons"
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};
