/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '../button/button';
import styles from './table-tools.module.scss';
import classNames from 'classnames';
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline';
import { ViewColumnsIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

import { ColumnsMenu } from '../columns-menu/columns-menu';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'flowbite-react';
import { Column, CustomButtonGroup } from '../../utils/interfaces';
import ClickAwayListener from 'react-click-away-listener';
import { RMContext } from '../../context/RMContext';
import { validateUserDownloadReports } from '../../utils/functions';
import { color } from 'echarts';

export interface HeaderTableProps {
    className?: string;
    title: string;
    columns: Column[];
    onSearch: (e: any) => void;
    searchText: string;
    onChangeColumns?: (newColumns: Column[]) => void | undefined;
    exportData?: Function;
    exportLoader?: boolean;
    reset?: Function;
    customButton?: CustomButtonGroup[];
    columnsButton: boolean;
    resetButton: boolean;
    isSearch: boolean;
    tableWidth?: string;
    expandTable: boolean;
    setExpandTable: Function;
    addRole?: CustomButtonGroup[];
    openModal?: boolean;
    setOpenModal?: Function;
    activeCompactMode?: boolean;
    isExpand?: boolean;
    hasAdditionalHeader?: boolean;
    additionalHeader?: any;
    filterByArrival?: boolean;
    filterByQuery?: any;
    queries?: any;
    isFilter?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableTools = ({
    className,
    title = 'default title',
    onSearch,
    searchText,
    columns,
    onChangeColumns = undefined,
    exportData,
    exportLoader = false,
    reset = () => {},
    customButton,
    columnsButton,
    resetButton,
    isSearch,
    expandTable,
    setExpandTable,
    addRole,
    openModal = false,
    setOpenModal,
    activeCompactMode = false,
    isExpand = true,
    hasAdditionalHeader = false,
    additionalHeader = null,
    filterByArrival,
    filterByQuery,
    queries,
    isFilter,
}: HeaderTableProps) => {
    const [close, setClose] = useState(true);
    const [searchPropertie, setSearchPropertie] = useState(searchText);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [allowSearch, setAllowSearch] = useState(false);
    const [arrivalButtonPressed, setArrivalButtonPressed] = useState('');
    const { userGlobals } = useContext(RMContext).userData;

    const onExportData = () => {
        if (exportData) {
            exportData(columns);
        }
    };

    const handleSearch = (event: any) => {
        const searchTerm = event.target.value;
        setAllowSearch(true);
        setSearchPropertie(searchTerm);
    };

    useEffect((): any => {
        if (allowSearch) {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }

            const newTimeout: any = setTimeout(() => {
                onSearch(searchPropertie);
                setAllowSearch(false);
            }, 500);

            setTypingTimeout(newTimeout);

            // Limpiar el temporizador cuando el componente se desmonte o cuando searchTerm cambie
            return () => clearTimeout(newTimeout);
        }
    }, [searchPropertie, searchText]);

    const handleOpenModal = () => {
        setExpandTable(!expandTable);
    };

    const handleAdditionalModal = () => {
        setOpenModal!(!openModal);
    };

    const arrivedValues = ['Yes', 'No'];
    const handleClick = (value: any) => {
        isFilter(true);

        if (arrivalButtonPressed === value) {
            setArrivalButtonPressed('');
            filterByQuery({ ...queries, arrived: null, sort_by: 'arrived,desc' });
        } else {
            setArrivalButtonPressed(value);
            value === 'Yes'
                ? filterByQuery({ ...queries, arrived: true })
                : filterByQuery({ ...queries, arrived: false });
        }
    };
    return (
        <div className={classNames(styles.root, className)}>
            {filterByArrival && (
                <div className={styles.toIncludeFilters}>
                    <div>
                        <p className={styles.paragraph}>Filter by arrival</p>
                        <div className={styles.groupButtons}>
                            {arrivedValues.map((x: any) => (
                                <Button
                                    key={'btn-m-'}
                                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                    style={{
                                        padding: '3px 12px',
                                        backgroundColor:
                                            x === arrivalButtonPressed ? '#333333' : '',
                                        color: x === arrivalButtonPressed ? 'white' : '',
                                    }}
                                    click={() => handleClick(x)}
                                >
                                    {x}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div
                        className={
                            'flex justify-between items-center bg-white text-dark-gray text-xs ' +
                            (activeCompactMode ? 'px-0.5 py-1 ' : 'px-2.5 py-5 ')
                        }
                    >
                        <p>{title}</p>
                        <div className="flex w-1/2 items-center gap-2 justify-end ">
                            {hasAdditionalHeader && additionalHeader ? additionalHeader() : <></>}
                            {isSearch ? (
                                <input
                                    type="text"
                                    className=" border border-light-gray rounded-sm py-1 px-2 min-w-1/2 "
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    value={searchPropertie}
                                    style={{ maxWidth: 200 }}
                                />
                            ) : (
                                <></>
                            )}
                            {resetButton ? (
                                <Button
                                    key={'bt-reset'}
                                    click={() => {
                                        setSearchPropertie('');
                                        reset('table');
                                    }}
                                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                                >
                                    <ArrowPathIcon className="h-4 w-4" /> Reset
                                </Button>
                            ) : (
                                <></>
                            )}
                            {columnsButton ? (
                                <div className="relative h-auto">
                                    <Button
                                        key={'bt-col'}
                                        click={() => setClose(!close)}
                                        styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                    >
                                        <ViewColumnsIcon className="h-4 w-4" /> Columns
                                    </Button>
                                    {!close && onChangeColumns && (
                                        <ClickAwayListener
                                            onClickAway={(event: any) => {
                                                if (!close && event.target?.id !== 'columnsTools')
                                                    setClose(true);
                                            }}
                                            className={styles.menuInTools}
                                        >
                                            <ColumnsMenu
                                                label={'Columns'}
                                                columns={columns}
                                                onClose={() => setClose(true)}
                                                updateColumns={(c: Column[]) => onChangeColumns(c)}
                                            />
                                        </ClickAwayListener>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}
                            {exportData && userGlobals.canDownload ? (
                                <Button
                                    key={'bt-export'}
                                    click={onExportData}
                                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                                >
                                    {exportLoader ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <ArrowDownTrayIcon className="h-4 w-4" />
                                    )}
                                    Export
                                </Button>
                            ) : (
                                <></>
                            )}
                            {customButton ? (
                                customButton?.map((b: CustomButtonGroup, index: number) => (
                                    <Button
                                        key={'option-' + index}
                                        styleProp={b.styleProp}
                                        click={b.onClick}
                                        children={b.children}
                                    ></Button>
                                ))
                            ) : (
                                <></>
                            )}
                            {addRole ? (
                                addRole?.map((b: CustomButtonGroup, index: number) => (
                                    <Button
                                        key={'bt-' + index + b.children.toString()}
                                        styleProp={b.styleProp}
                                        click={() => {
                                            handleAdditionalModal();
                                            b.onClick();
                                        }}
                                        children={b.children}
                                    ></Button>
                                ))
                            ) : (
                                <></>
                            )}

                            {isExpand && (
                                <button
                                    key={'bt-close'}
                                    style={{ color: 'black', width: 25 }}
                                    onClick={handleOpenModal}
                                    title={!expandTable ? 'Full Mode' : 'Close'}
                                >
                                    {!expandTable ? (
                                        <ArrowsPointingOutIcon
                                            style={{ color: 'black', width: 25 }}
                                        />
                                    ) : (
                                        <ArrowsPointingInIcon
                                            style={{ color: 'black', width: 25 }}
                                        />
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!filterByArrival && (
                <div
                    className={
                        'flex justify-between items-center bg-white text-dark-gray text-xs ' +
                        (activeCompactMode ? 'px-0.5 py-1 ' : 'px-2.5 py-5 ')
                    }
                >
                    <p>{title}</p>
                    <div className="flex w-1/2 items-center gap-2 justify-end ">
                        {hasAdditionalHeader && additionalHeader ? additionalHeader() : <></>}
                        {isSearch ? (
                            <input
                                type="text"
                                className=" border border-light-gray rounded-sm py-1 px-2 min-w-1/2 "
                                placeholder="Search"
                                onChange={handleSearch}
                                value={searchPropertie}
                                style={{ maxWidth: 200 }}
                            />
                        ) : (
                            <></>
                        )}
                        {resetButton ? (
                            <Button
                                key={'bt-reset'}
                                click={() => {
                                    setSearchPropertie('');
                                    reset('table');
                                }}
                                styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                            >
                                <ArrowPathIcon className="h-4 w-4" /> Reset
                            </Button>
                        ) : (
                            <></>
                        )}
                        {columnsButton ? (
                            <div className="relative h-auto">
                                <Button
                                    key={'bt-col'}
                                    click={() => setClose(!close)}
                                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                >
                                    <ViewColumnsIcon className="h-4 w-4" /> Columns
                                </Button>
                                {!close && onChangeColumns && (
                                    <ClickAwayListener
                                        onClickAway={(event: any) => {
                                            if (!close && event.target?.id !== 'columnsTools')
                                                setClose(true);
                                        }}
                                        className={styles.menuInTools}
                                    >
                                        <ColumnsMenu
                                            label={'Columns'}
                                            columns={columns}
                                            onClose={() => setClose(true)}
                                            updateColumns={(c: Column[]) => onChangeColumns(c)}
                                        />
                                    </ClickAwayListener>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        {exportData && userGlobals.canDownload ? (
                            <Button
                                key={'bt-export'}
                                click={onExportData}
                                styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                            >
                                {exportLoader ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <ArrowDownTrayIcon className="h-4 w-4" />
                                )}
                                Export
                            </Button>
                        ) : (
                            <></>
                        )}
                        {customButton ? (
                            customButton?.map((b: CustomButtonGroup, index: number) => (
                                <Button
                                    key={'option-' + index}
                                    styleProp={b.styleProp}
                                    click={b.onClick}
                                    children={b.children}
                                ></Button>
                            ))
                        ) : (
                            <></>
                        )}
                        {addRole ? (
                            addRole?.map((b: CustomButtonGroup, index: number) => (
                                <Button
                                    key={'bt-' + index + b.children.toString()}
                                    styleProp={b.styleProp}
                                    click={() => {
                                        handleAdditionalModal();
                                        b.onClick();
                                    }}
                                    children={b.children}
                                ></Button>
                            ))
                        ) : (
                            <></>
                        )}

                        {isExpand && (
                            <button
                                key={'bt-close'}
                                style={{ color: 'black', width: 25 }}
                                onClick={handleOpenModal}
                                title={!expandTable ? 'Full Mode' : 'Close'}
                            >
                                {!expandTable ? (
                                    <ArrowsPointingOutIcon style={{ color: 'black', width: 25 }} />
                                ) : (
                                    <ArrowsPointingInIcon style={{ color: 'black', width: 25 }} />
                                )}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
