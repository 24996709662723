import React, { useState, useRef, useEffect } from 'react';
import styles from './multi-select-dropdown.module.scss';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Notiflix from 'notiflix';

export interface MultiSelectProps {
    icon?: any;
    options?: any;
    chooseLegend?: any;
    onChange?: (selected: any[]) => void;
    reset?: boolean;
    filtersOpened?: any;
    setFiltersOpened?: any;
    name?: string;
    stlylePorts?: boolean
}

export const MultiSelectDropdown = ({
    icon,
    options,
    chooseLegend,
    onChange,
    reset,
    filtersOpened = null,
    setFiltersOpened = null,
    name = '',
    stlylePorts = false
}: MultiSelectProps) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedData, setSelectedData] = useState<any[]>([]);

    useEffect(() => {
        if (options && name) {
            const initiallySelected = [
                ...options.filter((option: any) => option.check).map((option: any) => option.label),
            ];
            if (initiallySelected.length === options.length) {
                setSelectedData(['All', ...initiallySelected]);
            } else {
                setSelectedData(initiallySelected);
            }
        } else {
            setSelectedData(['All', ...options]);
        }
    }, [options]);

    const [filterText, setFilterText] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    /* const customSort = (a: any, b: any) => {
        if (typeof a !== 'object') {
            if (a === 'DEFAULT') return 1;
            if (b === 'DEFAULT') return -1;

            if (a.toLowerCase() === a && b.toLowerCase() !== b) return -1;
            if (a.toLowerCase() !== a && b.toLowerCase() === b) return 1;

            return a.localeCompare(b);
        } else {
            if (a.label === 'DEFAULT') return 1;
            if (b.label === 'DEFAULT') return -1;

            if (a.label.toLowerCase() === a && b.label.toLowerCase() !== b) return -1;
            if (a.label.toLowerCase() !== a && b.label.toLowerCase() === b) return 1;

            return a.label.localeCompare(b);
        }
    };
 */
    const sortedOptions = [...options]; /* .sort(customSort); */

    const manageMenuState = (e: any) => {
        if (filtersOpened && e.target.id !== 'filterText') {
            let opeF = [...filtersOpened, name];
            setFiltersOpened(opeF);
        }
        if (e.target.id !== 'filterText') {
            setOpenMenu((value) => !value);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setFilterText('');
    }, [openMenu]);

    useEffect(() => {
        if (reset) {
            setSelectedData(['All']);
        }
    }, [reset]);

    const addSelectedValue = (option: any) => {
        setSelectedData((prevSelectedData:any) => {
            let newSelectedData;

            if (typeof option !== 'object') {
                if (option === 'All') {
                    if (prevSelectedData.includes('All')) {
                        newSelectedData = [];
                    } else {
                        newSelectedData = [
                            'All',
                            ...sortedOptions.map((opt) =>
                                typeof opt === 'object' ? opt.label : opt
                            ),
                        ];
                    }
                } else {
                    if (prevSelectedData.includes(option)) {
                        newSelectedData = prevSelectedData.filter((item:any) => item !== option);

                        if (newSelectedData.includes('All')) {
                            newSelectedData = newSelectedData.filter((item:any) => item !== 'All');
                        }
                    } else {
                        newSelectedData = [...prevSelectedData, option];
                        if (
                            newSelectedData.length === sortedOptions.length &&
                            !newSelectedData.includes('All')
                        ) {
                            newSelectedData = [
                                'All',
                                ...sortedOptions.map((opt:any) =>
                                    typeof opt === 'object' ? opt.label : opt
                                ),
                            ];
                        }
                    }
                }
            } else {
                if (option.label === 'All') {
                    if (prevSelectedData.includes('All')) {
                        newSelectedData = [];
                    } else {
                        newSelectedData = ['All', ...sortedOptions.map((opt) => opt.label)];
                    }
                } else {
                    if (prevSelectedData.includes(option?.label)) {
                        newSelectedData = prevSelectedData.filter((item:any) => item !== option.label);
                        if (newSelectedData.includes('All')) {
                            newSelectedData = newSelectedData.filter((item:any) => item !== 'All');
                            newSelectedData = newSelectedData.filter((item:any) => item !== 'All');
                        }
                    } else {
                        newSelectedData = [...prevSelectedData, option.label];
                        if (
                            newSelectedData.length === sortedOptions.length &&
                            !newSelectedData.includes('All')
                        ) {
                            newSelectedData = ['All', ...sortedOptions.map((opt) => opt.label)];
                        }
                    }
                }
            }

            onChange && onChange(newSelectedData || []);
            return newSelectedData;
        });
    };

    const filteredOptions = sortedOptions.filter((option:any) => {
        if (typeof option !== 'object')
            return option.toLowerCase().includes(filterText.toLowerCase());
        else return option?.label.toLowerCase().includes(filterText.toLowerCase());
    });
    const validateName = (name: string) => {
        // console.log("validateName", name, stlylePorts);
        if (((name.includes('port') || name.includes('country-glp')) && stlylePorts) ||
        ((name.includes('port') ) && !stlylePorts)) {
            // console.log("validateName", true);
            return true;
        }
        // console.log("validateName", false);
        return false;
    };
    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <div
                className={name !== '' && !validateName(name) ? styles.multiple : validateName(name) ? styles.multiplePort : styles.selectDropdown}
                onClick={(e: any) => manageMenuState(e)}
                style={
                    (name === 'country-glp' && !validateName(name)) 
                        ? {
                              width: '100%',
                              fontSize: '14px',
                              fontWeight: '700',
                              height: '23px',
                              border: '1px solid #e7e7e7e3',
                              borderRadius: '6px',
                              color: 'rgb(46 50 63)',
                          }
                        : {}
                }
            >
                <div
                    className={styles['leftDropdown' + name] || styles.leftDropdown}
                    style={ (name === 'country-glp' && !stlylePorts)  ? { paddingLeft: '0px' } : {}}
                >
                    <div>{icon}</div>
                    {openMenu ? (
                        <div
                            className={
                                name !== '' && !validateName(name) ? styles.dropdownLegendMultiple : validateName(name) ? styles.dropdownLegendMultiplePort : styles.dropdownLegend
                            }
                            style={ (name === 'country-glp' && !validateName(name))  ? { overflow: 'none', width: '100%' } : {}}
                        >
                            <input
                                id="filterText"
                                type="text"
                                value={filterText}
                                onChange={(e: any) => setFilterText(e.target.value)}
                                placeholder="Type to search..."
                                className={styles.searchInput}
                                style={name === 'country-glp' && !stlylePorts ? { height: '20px' } : {}}
                            />
                        </div>
                    ) : (
                        <div
                            className={
                                name !== '' && !validateName(name) ? styles.dropdownLegendMultiple : validateName(name) ? styles.dropdownLegendMultiplePort : styles.dropdownLegend
                            }
                            title={
                                selectedData?.includes('All')
                                    ? chooseLegend
                                    : selectedData.join(', ')
                            }
                        >
                            {/* {selectedData.includes('All') ? chooseLegend : typeof (selectedData) !== 'object' ? selectedData?.join(', ') : selectedData.map((a)=>a.label).join(', ')} */}
                            {selectedData.includes('All') || selectedData.length === 0
                                ? chooseLegend
                                : selectedData.join(', ')}
                            {/* {console.log(typeof (selectedData), "selectedData", selectedData)} */}
                        </div>
                    )}
                </div>
                <div className={name !== '' ? styles.icon : ''}>
                    <ChevronDownIcon className={name === 'country-glp' && !stlylePorts ? 'w-3 h-3' : 'w-4 h-4'} />
                </div>
            </div>
            {openMenu && (
                <div
                className={name !== '' && !validateName(name) ? styles['dropdownMenuMultiple'] : validateName(name) ? styles['dropdownMenuMultiplePort'] : styles.dropdownMenu}
                    style={
                        (name === 'country-glp' && !validateName(name))
                            ? {
                                  width: '100%',
                                  position: 'relative',
                                  display: 'grid',
                                  gridTemplateColumns: 'auto auto auto auto',
                                  justifyItems: 'center',
                              }
                            : {}
                    }
                >
                    <div
                        className={styles.option}
                        onClick={() =>
                            sortedOptions.length > 0 && typeof sortedOptions[0] !== 'object'
                                ? addSelectedValue('All')
                                : addSelectedValue({ label: 'All' })
                        }
                    >
                        <input
                            type="checkbox"
                            id="All"
                            style={{ cursor: 'pointer' }}
                            checked={selectedData?.includes('All')}
                            onChange={() => {}}
                        />
                        <label htmlFor="All" className={styles.countryFonts}>
                            All
                        </label>
                    </div>
                    {filteredOptions &&
                        filteredOptions.length > 0 &&
                        filteredOptions.map((option: any, index: number) => (
                            <div
                                key={index}
                                className={styles.option}
                                onClick={() => addSelectedValue(option)}
                            >
                                <input
                                    type="checkbox"
                                    id={typeof option !== 'object' ? option : option?.label}
                                    style={{ cursor: 'pointer' }}
                                    checked={selectedData.includes(
                                        typeof option !== 'object' ? option : option?.label
                                    )}
                                />
                                <label
                                    htmlFor={typeof option !== 'object' ? option : option?.label}
                                    className={
                                        typeof option !== 'object'
                                            ? styles.countryFonts
                                            : styles.optionFonts
                                    }
                                    style={ (name === 'country-glp' && !stlylePorts) ? { fontSize: '13px' } : {}}
                                >
                                    {typeof option !== 'object' ? option : option?.label}
                                </label>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};